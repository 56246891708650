<template>
  <div class="app-container summary-page">
    <eHeader
      :query="query"
      @toQuery="toQueryHandle"
      @loginInputHandle="loginInputHandle"
      @clear="clearHandle"
    />
    <!--表格渲染-->
    <el-table
      v-loading="loading"
      :data="data"
      stripe
      size="small"
      style="width: 100%;"
      show-summary
      :summary-method="getSummaries"
    >
      <el-table-column prop="transactionId" label="订单号"/>
      <el-table-column prop="MT4" label="MT账号">
        <template slot-scope="scope">
            <span v-if="scope.row.login!=null">{{scope.row.login}}<br></span>
            <span v-if="scope.row.group!=null">{{scope.row.group}}</span>
            <span v-if="scope.row.login==null&&scope.row.group==null">无</span>
        </template>
      </el-table-column>
      <el-table-column  label="客户信息" width="150">
        <template slot-scope="scope">
          <span v-if="scope.row.userName!=null">{{scope.row.userName}}<br></span>
          <span v-if="scope.row.custId!=null">{{scope.row.custId}}<br></span>
          <span v-if="scope.row.email!=null">{{scope.row.email}}</span>
          <span v-if="scope.row.custId==null&&scope.row.phone==null&&scope.row.email==null">无</span>
        </template>
      </el-table-column>
      <el-table-column prop="manager" label="客户经理">
        <template slot-scope="scope">
            <span v-if="scope.row.managerName!=null">{{scope.row.managerName}}<br></span>
            <span v-if="scope.row.managerId!=null">{{scope.row.managerId}}</span>
            <span v-if="scope.row.managerName==null&&scope.row.managerId==null">无</span>
        </template>
      </el-table-column>
      <el-table-column prop="payType" label="支付渠道">
         <template slot-scope="scope">
          <span>{{ scope.row.payDetail }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="currency" label="支付币种"/>
      <el-table-column prop="amount" label="金额 ($)"/>
      <el-table-column prop="status" label="审核状态">
        <template slot-scope="scope">
          <span>{{ scope.row.payStatus=='3'?parseDepositStatus(scope.row.status):'待支付' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="提交时间">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.createTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="updateTime" label="审核通过时间">
        <template slot-scope="scope">
          <span>{{ getAuditTime(scope.row.statusTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150px" align="center">
        <template slot-scope="scope">
          <check class="my-button" v-if="checkPermission(['ADMIN','DEPOSIT_ALL','DEPOSIT_VIEW'])" :data="scope.row" :sup_this="sup_this" :isCheck="false"/>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
  </div>
</template>

<script>
  import { gePayTypeCommon } from '@/utils/index'
  import { limitNumber } from '@/utils/validate'
import { mapGetters } from 'vuex'
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initData'
import { parseTime } from '@/utils/index'
import eHeader from '@/components/finance/deposit/header'
import check from '@/components/finance/deposit/view'
import { parseDepositStatus,parseCurrencyType,parsePayType } from '@/views/pt/ib/utils/index'
export default {
  name:'deposit',
  components: { eHeader,check },
  mixins: [initData],
  data() {
    return {
      loading: false,
      sup_this: this,
      statistics:{
        amountSum: 0,
        payTypes:[]
      }
    }
  },
  computed: {
    ...mapGetters([
      'payTypeOptions'
    ])
  },
  created() {
    if (this.payTypeOptions == null) {
      this.$store.dispatch("GetPayType");
    }
    this.$nextTick(() => {
      this.init();
    })
  },
  methods: {
    parseTime,
    checkPermission,
    parseDepositStatus,
    parsePayType,
    parseCurrencyType,
    beforeInit() {
      this.url = '/crm/tCustDepositWithdraw'
      const sort = 'id,desc'
      this.params = { page: this.page, size: this.size, sort: sort , type:1}
      const query = this.query;
      const transactionId = query.transactionId;
      const login = query.login;
      const status = query.status;
      const payType = query.payType;
      const createTime = this.parseTime(query.createTime);
      const updateTime = this.parseTime(query.updateTime);
      const chkStatus = query.chkStatus;
      const managerInfo = query.managerInfo;
      if (chkStatus!=null&&chkStatus!='') { this.params['status'] = chkStatus };
      if (managerInfo!=null && managerInfo!='') { this.params['managerInfo'] = managerInfo };
      if (transactionId!=null && transactionId!='') { this.params['transactionId'] = transactionId };
      if (login!=null && login!='') { this.params['login'] = login };
      if (status!=null && status!='') { this.params['status'] = status };
      if (createTime!=null && createTime!='') { this.params['createTime'] = createTime };
      if (updateTime!=null && updateTime!='') { this.params['updateTime'] = updateTime };
      if (payType!=null && payType!='' && (payType && payType.length)) {
        this.params['payTypes'] = this.gePayTypeCommon(payType);
      }
      if(this.query.custInfo){
        this.params.custInfo = this.query.custInfo;
      }
      if(this.query.startAuditTime){
        this.params.startAuditTime = this.parseTime(this.query.startAuditTime);
      }
      if(this.query.endAuditTime){
        this.params.endAuditTime = this.parseTime(this.query.endAuditTime);
      }
      return true
    },
    toQueryHandle(){
      this.page = 0;
      this.init();
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        switch (index) {
          case 0:
            sums[index] = '合计';
            break;
          case 6:
            sums[index] = `${this.statistics.amountSum}`;
            break;
        }
      });

      return sums;
    },
    loginInputHandle(){
      this.query.login = limitNumber(this.query.login);
    },
    gePayTypeCommon,
    getAuditTime(auditTime){
      if(auditTime){
        return parseTime(auditTime);
      }else {
        return '-'
      }
    },
    clearHandle(){
      this.query = {};
      this.toQuery();
    }
  }
}
</script>

<style scoped>

</style>
